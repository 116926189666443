import React from "react";
import officialPhoto from "../assets/images/maitham-awadh-photo.png";
import backgroundImage from "../assets/images/photo-background.png";
import slogan from "../assets/images/name-slogan.png";
function FirstFold() {
  return (
    <section
      id="intro"
      className="relative w-full bg-background flex flex-col overflow-hidden mt-[var(--navbar-height)] h-screen md:flex-row"
    >
      <div
        className="w-full absolute h-[300px] bottom-0 left-0 z-0 bg-cover"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className="h-[60%] w-[100%] order-1 md:w-[50%] z-10">
        <img
          className="h-[100%] object-cover md:w-[100%] md:h-auto"
          src={officialPhoto}
          alt="صورة ميثم عبدالله عوض"
        />
      </div>
      <div className="h-[40%] flex flex-col w-full justify-center items-center [&>*]:mb-5 md:w-[50%] md:h-[100%]">
        <img
          className="w-[80%] md:w-[50%] h-auto z-10"
          src={slogan}
          alt="شعار 2024"
        />
      </div>
    </section>
  );
}

export default FirstFold;
