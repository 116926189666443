import React from "react";
import priorities from "../data/priorities";
function Priorities() {
  return (
    <section
      id="priorities"
      className="scroll-mt-[var(--navbar-height)] bg-background p-5 border-t border-secondary flex flex-col [&>*]:text-primary"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        الأولويات الانتخابية
      </h2>
      <div className="md:grid md:grid-cols-4 md:gap-10">
        {priorities.map((el, index) => (
          <div key={`priorities-${index}`} className="[&>*]:mb-3">
            <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
              {el.name}
            </h3>
            <p className="text-justify md:text-2xl">{el.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Priorities;
