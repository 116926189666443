import React from "react";
import registrationImage from "../assets/images/maitham-awadh-registration-speak.png";
function Statement() {
  return (
    <section
      id="statement"
      className="scroll-mt-[var(--navbar-height)] bg-primary p-5 border-t border-secondary flex flex-col md:flex-row [&>*]:text-white"
    >
      <div className="md:w-[80%]">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-4">
          بيان خوض الانتخابات
        </h2>
        <main className="[&>p]:mb-3 [&>p]:text-justify md:w-[500px] md:mx-auto">
          <h3 className="text-xl font-bold text-center mb-4">
            بسم الله الرحمن الرحيم
          </h3>
          <p className="md:text-2xl">
            كي ننتشل البلاد من حالة عدم الاستقرار السياسي.
          </p>
          <p className="md:text-2xl">
            وحتى لا يُصبح الفساد عُرفاً وثقافةً، وسبباً لانهيار مؤسسات الدولة
            ودفاعاً عن مقدرات البلاد لتبقى الكويت دولة رفاه مستدام للمواطنين.
          </p>
          <p className="md:text-2xl">
            نعلن عن خوضنا لانتخابات مجلس الأمة 2024 عن الدائرة الأولى تحت شعار
            نصلح لتبقى.
          </p>
          <p className="md:text-2xl">والله ولي التوفيق</p>
        </main>
      </div>
      <div
        className="h-[150px] md:h-auto md:w-[20%] border-4 border-secondary rounded-xl cursor-pointer bg-cover bg-center flex items-center justify-center [&>*]:text-secondary [&>*]:text-2xl font-bold"
        style={{ backgroundImage: `url(${registrationImage})` }}
        onClick={() =>
          window.open(
            "https://www.instagram.com/p/C4dzrW1qZFf/",
            "_blank",
            "noopener noreferrer"
          )
        }
      >
        <span>خطاب الترشح</span>
      </div>
    </section>
  );
}

export default Statement;
