const priorities = [
  {
    rank: 0,
    name: "تعديل قانون الانتخاب",
    description:
      "اعتماد قانون انتخاب جديد يهدف إلى تعزيز العمل الجماعي بما يتناسب مع واقع الحياة السياسية الكويتية وثقافة المجتمع السياسي.",
  },
  {
    rank: 1,
    name: "تعديل قانون المفوضية العليا للانتخابات",
    description:
      "إشراف المفوضية العليا على كافة العمليات الانتخابية لاختيار مجالس الإدارات في مؤسسات المجتمع المدني والنقابات المهنية، وانتخاب أعضاء المجلس البلدي، وانتخاب أعضاء مجلس الأمة والاتحادات الطلابية. بالإضافة إلى تعديل تشكيل المفوضية العليا من خلال تعيين عدد من أعضائها عن طريق مجلس الأمة.",
  },
  {
    rank: 2,
    name: "تعديل اللائحة الداخلية للمجلس",
    description:
      "حسم الخلاف حول صحة انعقاد الجلسات في حال غياب الحكومة من خلال تعديل المادة (74) من اللائحة الداخلية.",
  },
  {
    rank: 3,
    name: "مكافحة الفساد",
    description:
      "دراسة مكامن الخلل في المنظومة السياسية والإدارية التي تجعل الفساد ممكناً في الدولة، ووضع حلول عملية لمحاربته وتجفيف منابعه، وبناء دولة مؤسسات صلبة كسبيل وحيد لديمومة الدولة واستقرارها. وتبني مشروع تطوير المنظومة الرقابية عبر دمج هيئة مكافحة الفساد بديوان المحاسبة، وتمكين ديوان المحاسبة من نقديم المخالفات الجسيمة للنيابة العامة بشكل مباشر.",
  },
  {
    rank: 4,
    name: "الجمع بين الوظيفتين",
    description:
      "إقرار قانون الجمع بين الوظيفتين سواء في القطاع الخاص أو العام دون الحاجة إلى موافقة الوزير المختص ما لم يتعارض ذلك مع أوقات العمل، لمنح المواطن فرصة زيادة دخله، وتحسين مستوى معيشته، دون تكلفة على المال العام.",
  },
  {
    rank: 5,
    name: "الجمع بين الوظيفة والدراسة",
    description:
      "إقرار قانون الدراسة الجزئية للموظفين خارج ساعات العمل، لمنحهم الفرصة في زيادة مستواهم العلمي والثقافي، واكتساب مهارات جديدة تؤهلهم للحصول على وظائف ذات مردود مادي أعلى.",
  },
  {
    rank: 5,
    name: "القرض الحسن وعلاوة غلاء المعيشة",
    description:
      "استعجال إقرار زيادة أمثال القرض الحسن للمتقاعدين، وزيادة علاوة غلاء المعيشة للمواطنين المستحقين.",
  },
  {
    rank: 6,
    name: "تعديل سلم الرواتب لموظفي القطاع العام",
    description:
      "إقرار التعديلات اللازمة التي من شأنها مساواة المواطنين أصحاب المؤهلات المتشابهة في قطاعات الدولة العامة، وتقليص الفجوة في سلم رواتب الوزارات والهيئات والقطاعات الحكومية.",
  },
  {
    rank: 7,
    name: "زيادة بدل دعم العمالة الوطنية",
    description:
      "النظر في زيادة دعم العمالة الوطنية لخلق بيئة جاذبة في القطاع الخاص أو العمل الحر، وتخفيف الأعباء المالية على الدولة في مسألة التوظيف، والحد من البطالة المقنعة في القطاع العام، كما يجب وربط الزيادة السنوية بمعدلات التضخم وفق بيانات بنك الكويت المركزي.",
  },
  {
    rank: 8,
    name: "تعزيز الأمان الوظيفي لموظفي القطاع الخاص",
    description:
      "من خلال إقرار نظام التأمين الوظيفي، وصرف دعم العمالة والتأمين الصحي في حال عمل الموظف خارج الكويت أو العلاج في الخارج، وصرف بدل البطالة لمدة سنة وهو ضعف الفترة الحالية (6) شهور فقط.",
  },
  {
    rank: 9,
    name: "تحفيز الشركات الصغيرة على التوظيف",
    description:
      "يجب خلق قطاعات جديدة تستقطب الخريجين وتساهم في خلق فرص عمل للمواطنين، منها قطاع المشاريع الصغيرة. يتم ذلك من خلال تخفيض اشتراكات التأمينات على الشركات الصغيرة، وإعطائها أولوية المشتريات الحكومية، وإشراكها مع الشركات الكبرى في تنفيذ المناقصات الحكومية، وحماية الموظف من انقطاع الراتب.",
  },
  {
    rank: 10,
    name: "دعم العاملين في القطاع الخاص على الباب الخامس",
    description:
      "من خلال مساواة العاملين في القطاع الخاص على الباب الخامس مع الباب الثالث بصرف علاوة الأولاد والعلاوة الزوجية بعد التقاعد، وتطبيق الحد الأدنى للمعاش التقاعدي للباب الخامس أسوة في الباب الثالث، وخفض السن التقاعدي للمرأة ومساواتها في الباب الثالث.",
  },
  {
    rank: 11,
    name: "انتخاب مجلس إدارة الجمعيات كل 4 سنوات",
    description:
      "إقرار نظام القوائم وإلغاء الصوت الواحد، واعتماد نظام الأربع سنوات لمجالس إدارة الجمعيات التعاونية لتعزيز استقرارها الإداري.",
  },
  {
    rank: 12,
    name: "التفرغ الكامل لمجلس إدارة الجمعية",
    description:
      "اعتماد نظام التفرغ الكامل لأعضاء مجلس الإدارة مع الحصول على كامل رواتبهم، وفي حال كانوا موظفي القطاع الخاص، يتم تعويضهم من قبل وزارة الشؤون براتب يعادل راتبهم الشهري السابق.",
  },
  {
    rank: 13,
    name: "منح الجمعية حق استملاك الأصول الاستثمارية",
    description:
      "تعديل قانون الجمعيات التعاونية للسماح للجمعيات بالاستثمار المباشر في الأصول لزيادة أرباح المساهمين السنوية، وإمكانية استملاك الأراضي الزراعية والثروة الحيوانية لتعزيز الأمن الغذائي الاستراتيجي.",
  },
  {
    rank: 14,
    name: "إشراف الجمعية على مرافق المنطقة",
    description:
      "إشراف الجمعيات التعاونية على المرافق الترفيهية والرياضية والخدمية لتطويرها وإدارتها للحفاظ على جودتها بما يلبي احتياجات وتطلعات أهالي المنطقة، وهو نموذج متبع في العديد من الدول المتقدمة تحت مسمى المجالس المحلية أو البلديات المحلية.",
  },
];
export default priorities;
