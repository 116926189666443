const cities = [
  {
    order: 0,
    name: "الرميثية",
  },
  {
    order: 0,
    name: "بيان",
  },
  {
    order: 0,
    name: "مشرف",
  },
  {
    order: 0,
    name: "الدعية",
  },
  {
    order: 0,
    name: "الشعب",
  },
  {
    order: 0,
    name: "الدسمة",
  },
  {
    order: 0,
    name: "شرق",
  },
  {
    order: 0,
    name: "المطبة",
  },
  {
    order: 0,
    name: "دسمان",
  },
  {
    order: 0,
    name: "بنيدالقار",
  },
  {
    order: 0,
    name: "فيلكا",
  },
  {
    order: 0,
    name: "حولي",
  },
  {
    order: 0,
    name: "النقرة",
  },
  {
    order: 0,
    name: "ميدان حولي",
  },
  {
    order: 0,
    name: "السالمية",
  },
  {
    order: 0,
    name: "سلوى",
  },
  {
    order: 0,
    name: "مبارك العبدالله",
  },
];

export default cities;
