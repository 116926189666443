import FirstFold from "./sections/FirstFold";
import Resume from "./sections/Resume";
import Priorities from "./sections/Priorities";
import Statement from "./sections/Statement";
import Footer from "./sections/Footer";
import Links from "./sections/Links";
import Interviews from "./sections/Interviews";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <FirstFold />
      <Statement />
      <Priorities />
      <Resume />
      <Interviews />
      <Links />
      <Footer />
    </div>
  );
}

export default App;
