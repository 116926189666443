import React from "react";

function Navbar() {
  return (
    <nav className="z-50 fixed top-0 right-0 w-screen h-[var(--navbar-height)] bg-secondary text-white text-xl font-bold">
      <ul className="p-5 h-[var(--navbar-height)] flex flex-row justify-start items-center gap-5 [&>li]:flex-shrink-0 overflow-x-auto overflow-y-hidden">
        <li>
          <a href="#intro">مقدمة</a>
        </li>
        <li>
          <a href="#statement">بيان خوض الانتخابات</a>
        </li>
        <li>
          <a href="#priorities">الأولويات الانتخابية</a>
        </li>
        <li>
          <a href="#resume">السيرة الذاتية</a>
        </li>
        <li>
          <a href="#interviews">مقابلات</a>
        </li>
        <li>
          <a href="#links">روابط</a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
