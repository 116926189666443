import React from "react";

function Resume() {
  return (
    <section
      id="resume"
      className="scroll-mt-[var(--navbar-height)] bg-primary p-5 border-t border-secondary flex flex-col [&>*]:text-white"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        السيرة الذاتية
      </h2>
      <div className="md:grid md:grid-cols-3 md:gap-10 md:text-2xl">
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            الخبرة العملية
          </h3>
          <ul className="list-disc mr-5">
            <li>أعمال حرة</li>
            <li>موظف بوزارة التجارة والصناعة</li>
          </ul>
        </div>
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            الشهادات العلمية
          </h3>
          <ul className="list-disc mr-5">
            <li>
              بكالوريوس إدارة الأعمال والتسويق، الجامعة الأمريكية في الكويت
            </li>
            <li>دبلوم إدارة الأعمال، الكلية الاسترالية</li>
          </ul>
        </div>
        <div>
          <h3 className="text-secondary font-bold text-right text-2xl md:text-3xl">
            النشاط النقابي
          </h3>
          <ul className="list-disc mr-5">
            <li>رئيس مجلس إدارة جمعية الرميثية 2018-2019</li>
            <li>عضو مجلس إدارة جمعية الرميثية 2017-2021</li>
            <li>رئيس مجلس طلبة الكلية الأسترالية 2004-2006</li>
            <li>عضو الجمعية الاقتصادية الكويتية</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Resume;
