import mafaheem from "../assets/images/podcast-mafaheem.png";
import circle from "../assets/images/circle-podcast.png";
import corner from "../assets/images/podcast-corner-seyasi.jpg";
const interviews = [
  {
    name: "مقابلة بودكاست كورنر سياسي",
    link: "https://youtu.be/07EhdiorY94?si=diBwqpA4DgxAKdmG",
    screenshot: corner,
  },
  {
    name: "مقابلة بودكاست مفاهيم",
    link: "https://youtu.be/pqV_N86EZ_I?si=ilCTrchRkWl33LEH",
    screenshot: mafaheem,
  },
  {
    name: "مقابلة بودكاست دائرة",
    link: "https://youtu.be/_cCJdW2h-Zo?si=b7k66ms6y4F8vS0q",
    screenshot: circle,
  },
];
export default interviews;
