import React from "react";
import links from "../data/links";
function Links() {
  return (
    <section
      id="links"
      className="scroll-mt-[var(--navbar-height)] bg-background p-5 border-t border-secondary flex flex-col [&>*]:text-primary"
    >
      <h2 className="text-3xl font-bold text-center mb-4">روابط</h2>
      <div className="md:w-[600px] md:mx-auto md:grid md:grid-cols-4 md:gap-4">
        {links.map((el, index) => (
          <button
            type="button"
            key={`links-${index}`}
            className="md:border-0 border-2 w-[100%] border-primary rounded-lg h-[60px] flex flex-row items-center justify-center mb-3"
            onClick={() => window.open(el.link, "_blank")}
          >
            <span className="w-[50%] flex justify-end items-center">
              <img src={el.icon} alt="" className="h-[30px] w-auto ml-2" />
            </span>
            <span className="flex-1 w-[50%] text-right ">{el.name}</span>
          </button>
        ))}
      </div>
    </section>
  );
}

export default Links;
