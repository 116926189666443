import xIcon from "../assets/images/x-social-media-icon.png";
import instagramIcon from "../assets/images/instagram-icon.svg";
import tiktokIcon from "../assets/images/tiktok-social-media-icon.png";
import whatsappIcon from "../assets/images/icons8-whatsapp.svg";

const links = [
  { name: "اكس", link: "https://x.com/maithamawadh", icon: xIcon },
  {
    name: "انستغرام",
    link: "https://www.instagram.com/maithamawadh",
    icon: instagramIcon,
  },
  {
    name: "اللجنة الإعلامية",
    link: "https://wa.me/96557777817",
    icon: whatsappIcon,
  },
  // {
  //   name: "تكتوك",
  //   link: "https://tiktok.com/@hamadhomud",
  //   icon: tiktokIcon,
  // },
];
export default links;
